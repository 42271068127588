import React, { useEffect } from 'react';
import { Box, Typography as T } from '@fuji-ui/core';
import { Wrapper } from '../components/layout';
import { navigate, Link } from 'gatsby';

import SEO from '../components/seo';

const NotFoundPage = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/');
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <SEO title="404: Not found" />
      <Box>
        <Wrapper>
          <T.H1>NOT FOUND</T.H1>
          <T.P>
            You just hit a page that doesn't exist. You'll be redirecting to the
            home page.
          </T.P>
          <T.P>
            <Link to="/">Back to the home page</Link>
          </T.P>
        </Wrapper>
      </Box>
    </>
  );
};

export default NotFoundPage;
